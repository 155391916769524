<template>
  <div class="content-body">
    <div class="container text-start">
      <div class="row">
        <div class="col-xxl-6">
          <div
            class="promotion d-flex justify-content-between align-items-center"
          >
            <div class="promotion-detail">
              <h3 class="text-white mb-3">Kimon</h3>
              <p>
                Kimon is a set of 10,000 stunning art pieces with unique traits
                and an attention to detail that is rarely seen anywhere on the
                blockchain.
              </p>
              <a
                :href="nftMarketPlace + '/collection/' + currColl.nftCA"
                target="_blank"
                class="btn btn-secondary me-3"
                >Buy on Oasis</a
              >
            </div>
          </div>
        </div>
        <div class="col-xxl-6">
          <div class="card top-bid">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-md-4">
                  <img
                    src="/images/avatar/kimon.png"
                    class="img-fluid rounded"
                    alt="..."
                  />
                </div>
                <NftTop @refreshEvent="reloadChildren" ref="nftTop" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xxl-6">
      <div class="card top-bid">
        <div class="card-body">
          <div class="row align-items-center">
            <h4>
              Please note that you are viewing Kimons in the new contract.
            </h4>

            <h4>
              Currently, staked Kimons from the old contract are not shown here,
              but they can be found on
              <router-link to="/Kimon"
                >the page for the old contract</router-link
              >
              .
            </h4>
          </div>
        </div>
      </div>
    </div>

    <Nft @refreshEvent="reloadChildren" ref="nft" :currColl="currColl" />
  </div>
</template>

<script>
import Nft from "@/components/Nft.vue";
import NftTop from "@/components/NftTopNew.vue";

import initialStakes from "../dogeMainnet/kimonNew-staked.json";

import power from "../dogeMainnet/kimon-power.json";

import minpower from "../dogeMainnet/kimon-minpower.json";

export default {
  name: "App",
  components: { Nft, NftTop },

  data() {
    return {
      currColl: {
        name: "kimon",
        fullName: "Kimon New Contract",
        nftCA: window.address["kimon"],
        power: power,
        minpower: minpower,
        initialStakes: initialStakes,
        pictureLink:
          "https://raw.githubusercontent.com/NftClubCash/Kimon/main/PNG/",
        discountExpTokensX1k: 1000,
        expForLvl0: 1700,
      },

      nftMarketPlace: window.nftMarketPlace,
    };
  },
  methods: {
    reloadChildren: function () {
      this.$refs.nftTop.load();
      this.$refs.nft.load();
    },
  },
};
</script>
